import React from 'react';
import EventForm from './EventForm';
import './App.css';

function App() {
  return (
    <div>
      
      <EventForm />
    </div>
  );
}

export default App;
