import React from 'react';
import { Formik, Form, Field } from 'formik';
import {firestore} from "./index";
import { collection, addDoc } from "firebase/firestore";

const convertToDate = (dateString, timeString) => {
    const date = new Date(dateString);
    const time = timeString.split(':');
    date.setHours(time[0], time[1]);
    return date;
}

const EventForm = () => {
  const handleSubmit = async (values) => {
    console.log('Form values:', values);
    values.created_at = new Date();
    values.start_date = convertToDate(values.start_date, values.start_time);
    values.end_time = convertToDate(values.start_date, values.end_time);
    delete values.start_time;
    try {
        console.log('Form values:', values);
        await addDoc(collection(firestore, 'events'), values);
        console.log('Form data saved to Firestore successfully!');
    } catch (error) {
        console.error('Error saving form data to Firestore:', error);
    }
};

    return (
      <Formik
        initialValues={{
          name: '',
          description: '',
          start_date: '',
          start_time: '',
          average_duration: '',
          end_time: '',
          url: ''
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          <label htmlFor="name">Name</label>
          <Field id="name" name="name" type="text"/>

          <label htmlFor="description">Description</label>
          <Field id="description" name="description" as="textarea" />

          <label htmlFor="start_date">Date</label>
          <Field id="start_date" name="start_date" type="date" />

          <label htmlFor="start_time">Start time</label>
          <Field id="start_time" name="start_time" type="time" />

          <label htmlFor="end_time">End time</label>
          <Field id="end_time" name="end_time" type="time" />

          <label htmlFor="average_duration">Average duration in minutes</label>
          <Field id="average_duration" name="average_duration" type="number"/>

          <label htmlFor="location">Location</label>
          <Field id="location" name="location" type="location" />

          <label htmlFor="time">Url</label>
          <Field id="url" name="url" type="url" />

          <button type="submit">Submit</button>
        </Form>
      </Formik>
    );
  };



export default EventForm;
