import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyBq02iTFs-X7BUWNrG2ObtmKz4MG-T4O7g",
    authDomain: "bg-llm-rec-exp.firebaseapp.com",
    projectId: "bg-llm-rec-exp",
    storageBucket: "bg-llm-rec-exp.appspot.com",
    messagingSenderId: "956350486499",
    appId: "1:956350486499:web:01b44e00c155bed92fb2d6",
    measurementId: "G-F9M5CF50MX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

export { firestore }



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
reportWebVitals();
